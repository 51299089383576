/* eslint-disable eol-last */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService } from "ngx-mqtt";
import { Observable } from "rxjs";


@Injectable()
export class EventMqttService {

  private endpoint: string;

  constructor(
    private _mqttService: MqttService,
  ) {
    this.endpoint = 'events';
  }

  topic(deviceId: string): Observable<IMqttMessage> {
    //let topicName = `/${this.endpoint}/${deviceId}`;     
    let topicName = `/lat/turnos/pantalla/${deviceId}`; 
    return this._mqttService.observe(topicName);
  }
}